const Klaviyo = require("node-klaviyo");
import mixpanel from "mixpanel-browser";
import { decodeBase64 } from "@helpers/common";

let ifProduction = process.env.ENV === "PRODUCTION";
// let ifProduction = true;

// -----------------------------------------------------------
// FACEBOOK
// -----------------------------------------------------------
export const FacebookPageView = async () => {
  if (window.FB_INITIALIZED) {
    fbq("track", "PageView");
  }
};

export const FacebookViewContent = async (productObject) => {
  if (window.FB_INITIALIZED) {
    let facebookViewContentData = {
      content_ids: [productObject.variation.shopifyProductGroupID],
      content_name: productObject.marketingName,
      content_type: "product_group",
      currency: "USD",
      value: productObject.price,
    };
    fbq("track", "ViewContent", facebookViewContentData);
  }
};

export const FacebookAddToCart = async (productObject) => {
  if (window.FB_INITIALIZED) {
    let facebookViewContentData = {
      content_ids: [productObject.variation.shopifyProductGroupID],
      content_name: productObject.marketingName,
      content_type: "product_group",
      currency: "USD",
      value: productObject.price,
    };
    fbq("track", "AddToCart", facebookViewContentData);
  }
};

// -----------------------------------------------------------
// Attentive
// -----------------------------------------------------------
export const AttentiveViewProduct = async (productObject) => {
  if (window.attentive) {
    window.attentive.analytics.productView({
      items: [
        {
          productId: productObject.variation.shopifyProductGroupID,
          productVariantId: decodeBase64(productObject.shopifyID).split("/")[4],
          name: productObject.marketingName,
          productImage: productObject.images.cartImage,
          category: "soap",
          price: {
            value: productObject.price.toString(),
            currency: "USD",
          },
          quantity: 1,
        },
      ],
    });
  }
};

export const AttentiveAddToCart = async (productObject) => {
  if (window.attentive) {
    window.attentive.analytics.addToCart({
      items: [
        {
          productId: productObject.variation.shopifyProductGroupID,
          productVariantId: decodeBase64(productObject.shopifyID).split("/")[4],
          name: productObject.marketingName,
          productImage: productObject.images.cartImage,
          category: "soap",
          price: {
            value: productObject.price.toString(),
            currency: "USD",
          },
          quantity: 1,
        },
      ],
      cart: {
        cartCoupon: "",
        cartId: localStorage.getItem("cartID"),
      },
    });
  }
};

// -----------------------------------------------------------
// TikTock
// -----------------------------------------------------------
export const TickTockAddToCart = async () => {
  // If the is in production mode
  if (window.TT_INITIALIZED) {
    ttq.track("AddToCart");
  }
};

// -----------------------------------------------------------
// KLAVIYO
// -----------------------------------------------------------
const KlaviyoClient = new Klaviyo({
  publicToken: process.env.KLAVIYO_PUBLIC_KEY,
});
export const KlaviyoGlobalPixel = async (user) => {
  if (user) {
    KlaviyoClient.public.identify({
      email: user.email,
      properties: {
        $first_name: user.firstName,
        $last_name: user.lastName,
      },
      post: true, //defaults to false
    });
  } else {
    if (localStorage.getItem("analyticsEmail")) {
      KlaviyoClient.public.identify({
        email: localStorage.getItem("analyticsEmail"),
        post: true,
      });
    }
  }
};

export const KlaviyoViewedProduct = async (productObject, user) => {
  let email = null;
  if (user) {
    email = user.email;
  } else {
    if (localStorage.getItem("analyticsEmail")) {
      email = localStorage.getItem("analyticsEmail");
    }
  }
  if (email) {
    KlaviyoClient.public.track({
      event: "Viewed Product",
      email: email,
      properties: {
        ProductName: productObject.marketingName,
        ProductID: productObject.cleancultID,
        ImageURL: productObject.images.listingImage,
        HeroImageURL: productObject.images.heroImages[0],
        URL: `https://www.cleancult.com/products/${productObject.urlKey}`,
        Price: productObject.price,
        CompareAtPrice: productObject.subscriptionPrice,
      },
      post: true,
    });
  }
};

export const KlaviyoMembershipStatusUpdate = async (membership, user, cancellationReason) => {
  let event = "Membership Cancelled";
  if (!cancellationReason) {
    event = "Membership Reactivated";
  }
  let items = [];
  let ItemNames = [];

  membership.lineItems.map(function (lineItemObject) {
    ItemNames.push(lineItemObject.product.marketingName);
    items.push({
      imageUrl: lineItemObject.product.images.cartImage,
      name: lineItemObject.product.marketingName,
      quantity: lineItemObject.quantity,
    });
    return lineItemObject;
  });

  KlaviyoClient.public.track({
    event: event,
    email: user.email,
    properties: {
      membershipInformation: membership.subscriptionDetails,
      reason: cancellationReason,
      items: items,
      ItemNames: ItemNames,
    },
    post: true, //defaults to false
  });
};

// -----------------------------------------------------------
// GOOGLE ANALYTICS
// -----------------------------------------------------------
export const GoogleAnalyticsPageView = async () => {
  if (window.GA_INITIALIZED) {
    ga("send", "pageview");
  }
};

// -----------------------------------------------------------
// MIXPANEL
// -----------------------------------------------------------
export const MixPanelGlobalPixelAndPageView = async () => {
  // If the is in production mode
  if (!window.MIXPANEL_INITIALIZED) {
    // For mix panel only use the development version of mixpanel
    if (process.env.ENV === "DEVELOPMENT") {
      mixpanel.init("1171a2a7ddc542216d6970daa6e1e088");
    } else {
      // mixpanel.init('1171a2a7ddc542216d6970daa6e1e088');
      mixpanel.init("79e975afcffded8a83f47fe9f628e957");
    }
    window.MIXPANEL_INITIALIZED = true;
    mixpanel.track("Link Click");
  } else {
    mixpanel.track("Link Click");
  }
};

export const MixPanelTrackEvent = async (event, options) => {
  // If the is in production mode
  if (window.MIXPANEL_INITIALIZED) {
    if (!options) {
      options = {};
    }
    mixpanel.track(event, options);
  }
};

export const ExtractMixPanelID = async () => {
  if (window.MIXPANEL_INITIALIZED) {
    let mixpanelID = await mixpanel.get_distinct_id();
    return mixpanelID;
  } else {
    return "";
  }
};

// --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// -------------- GLOBAL ANALYTICS FUNCTIONS --------------
// --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

// --------------------------
// GENERAL & PAGE VIEWS
// --------------------------
export const AnalyticsTagsAndPageViews = async (user) => {
  if (ifProduction) {
    if (user && user.loading) {
      user = null;
    }
    KlaviyoGlobalPixel(user);
    GoogleAnalyticsPageView();
    MixPanelGlobalPixelAndPageView();
    FacebookPageView();
  }
};

export const AnalyticsLandedOnHomePage = async (homePageScenario) => {
  if (ifProduction) {
    if (homePageScenario) {
      MixPanelTrackEvent("Landed On Home Page", {
        scenarioID: homePageScenario.id,
        scenario: homePageScenario.scenario,
        loggedIn: localStorage.getItem("accessToken") ? true : false,
      });
    } else {
      MixPanelTrackEvent("Landed On Home Page", {
        loggedIn: localStorage.getItem("accessToken") ? true : false,
      });
    }
  }
};

// --------------------------
// AUTHENTICATION
// --------------------------
export const AnalyticsLogin = async (user) => {
  if (ifProduction) {
    // // // console.log(user)
    // // // console.log("AnalyticsLogin")
    MixPanelTrackEvent("Logged In", {
      email: user.email,
      userID: decodeBase64(user.id),
    });
  }
};

export const AnalyticsRegister = async (user) => {
  if (ifProduction) {
    // // // console.log(user)
    // // // console.log("AnalyticsRegister")
    MixPanelTrackEvent("Registered Account", {
      email: user.email,
      userID: decodeBase64(user.id),
    });
  }
};

// --------------------------
// ECOMMERCE
// --------------------------
export const AnalyticsViewProduct = async (productObject, user) => {
  if (ifProduction) {
    // // // console.log("AnalyticsViewProduct")
    if (user && user.loading) {
      user = null;
    }

    AttentiveViewProduct(productObject, user);
    KlaviyoViewedProduct(productObject, user);
    FacebookViewContent(productObject);
    MixPanelTrackEvent("Viewed Product", {
      cleancultID: productObject.cleancultID,
      bestSeller: productObject.bestSeller,
    });
  }
};

export const AnalyticsViewProductFeed = async (productCategory) => {
  if (ifProduction) {
    // // // console.log("AnalyticsViewProductFeed")
    // // // console.log(productCategory)
    MixPanelTrackEvent("Viewed Product Feed", { category: productCategory });
  }
};

export const AnalyticsAddItemToCart = async (productObject, sellingPlan, location) => {
  if (ifProduction) {
    // // // console.log("AnalyticsAddItemToCart")
    // // // console.log(productObject)
    // // // console.log(productObject, sellingPlan, location)
    FacebookAddToCart(productObject, sellingPlan);
    TickTockAddToCart();
    AttentiveAddToCart(productObject);
    MixPanelTrackEvent("Added Item To Cart", {
      cleancultID: productObject.cleancultID,
      bestSeller: productObject.bestSeller,
      ifSubscription: sellingPlan ? true : false,
      location: location,
    });
  }
};

export const AnalyticsViewCart = async (cartObject) => {
  if (ifProduction) {
    // // // console.log("AnalyticsViewCart")
    // // // console.log(cartObject)
    MixPanelTrackEvent("Viewed Cart", {
      cartID: decodeBase64(cartObject.id),
      total: parseFloat(cartObject.estimatedCost.totalAmount.amount),
      lineItems: [
        cartObject.lines.map((lineItemObject) => {
          return {
            id: lineItemObject.id,
            cleancultID: lineItemObject.product.cleancultID,
            quantity: lineItemObject.quantity,
          };
        }),
      ],
    });
  }
};

export const AnalyticsViewFreeGiftPromo = async (freeGiftProductObject, cartObject) => {
  if (ifProduction) {
    // // // console.log("AnalyticsViewFreeGiftPromo")
    // // // console.log(freeGiftProductObject)
    MixPanelTrackEvent("Viewed Free Gift Promo In Cart", {
      cartID: decodeBase64(cartObject.id),
      total: parseFloat(cartObject.estimatedCost.totalAmount.amount),
      lineItems: [
        cartObject.lines.map((lineItemObject) => {
          return {
            id: lineItemObject.id,
            cleancultID: lineItemObject.product.cleancultID,
            quantity: lineItemObject.quantity,
          };
        }),
      ],
      freeGiftCleancultID: freeGiftProductObject.cleancultID,
    });
  }
};

export const AnalyticsRemoveItemFromCart = async (lineItemObject) => {
  if (ifProduction) {
    // // // console.log("AnalyticsRemoveItemFromCart")
    // // // console.log(lineItemObject)
    MixPanelTrackEvent("Removed Cart Item", {
      cleancultID: lineItemObject.product.cleancultID,
      ifSubscription: lineItemObject.sellingPlan ? true : false,
    });
  }
};

export const AnalyticsInitiateCheckout = async (cartObject) => {
  if (ifProduction) {
    // // // console.log("AnalyticsInitiateCheckout")
    // // // console.log(cartObject)
    MixPanelTrackEvent("Initiated Checkout", {
      cartID: decodeBase64(cartObject.id),
      total: parseFloat(cartObject.estimatedCost.totalAmount.amount),
      lineItems: [
        cartObject.lines.map((lineItemObject) => {
          return {
            id: lineItemObject.id,
            cleancultID: lineItemObject.product.cleancultID,
            quantity: lineItemObject.quantity,
          };
        }),
      ],
    });
  }
};

// --------------------------
// MEMBERSHIP
// --------------------------
export const AnalyticsCancelledMembership = async (membership, user, cancellationReason) => {
  if (ifProduction) {
    // // // console.log("AnalyticsCancelledMembership")
    KlaviyoMembershipStatusUpdate(membership, user, cancellationReason);
    MixPanelTrackEvent("Cancelled Membership", {
      userID: decodeBase64(user.id),
      userEmail: user.email,
      cancellationReason: cancellationReason,
      membershipItems: [
        membership.lineItems.map((lineItemObject) => {
          return {
            cleancultID: lineItemObject.product.cleancultID,
            quantity: lineItemObject.quantity,
          };
        }),
      ],
    });
  }
};

export const AnalyticsActivatedMembership = async (membership, user) => {
  if (ifProduction) {
    // // // console.log("AnalyticsActivatedMembership")
    KlaviyoMembershipStatusUpdate(membership, user);
    MixPanelTrackEvent("Activated Membership", {
      userID: decodeBase64(user.id),
      userEmail: user.email,
      membershipItems: [
        membership.lineItems.map((lineItemObject) => {
          return {
            cleancultID: lineItemObject.product.cleancultID,
            quantity: lineItemObject.quantity,
          };
        }),
      ],
    });
  }
};

export const AnalyticsAddMembershipItem = async (productObject, ifSubscription, user, location) => {
  if (ifProduction) {
    // // // console.log("AnalyticsAddMembershipItem")
    // // // console.log(productObject, ifSubscription, user)
    MixPanelTrackEvent("Added Membership Item", {
      userID: decodeBase64(user.id),
      userEmail: user.email,
      ifSubscription: ifSubscription,
      cleancultID: productObject.cleancultID,
      location: location,
    });
  }
};

export const AnalyticsRemovedMembershipItem = async (productObject, user) => {
  if (ifProduction) {
    // // // console.log("AnalyticsRemovedMembershipItem")
    // // // console.log(productObject)
    MixPanelTrackEvent("Removed Membership Item", {
      userID: decodeBase64(user.id),
      userEmail: user.email,
      cleancultID: productObject.cleancultID,
    });
  }
};

export const AnalyticsSkippedMembershipItem = async (productObject, user) => {
  if (ifProduction) {
    // // // console.log("AnalyticsSkippedMembershipItem")
    // // // console.log(productObject)
    MixPanelTrackEvent("Skipped Membership Item", {
      userID: decodeBase64(user.id),
      userEmail: user.email,
      cleancultID: productObject.cleancultID,
    });
  }
};

export const AnalyticsUnskippedMembershipItem = async (productObject, user) => {
  if (ifProduction) {
    // // // console.log("AnalyticsUnskippedMembershipItem")
    // // // console.log(productObject)
    MixPanelTrackEvent("Unskipped Membership Item", {
      userID: decodeBase64(user.id),
      userEmail: user.email,
      cleancultID: productObject.cleancultID,
    });
  }
};

export const AnalyticsPushedBackMembership = async (scenerio, user) => {
  if (ifProduction) {
    // // // console.log("AnalyticsPushedBackMembership")
    // // // console.log(scenerio)
    MixPanelTrackEvent("Pushed Back Membership Context Action", {
      userID: decodeBase64(user.id),
      userEmail: user.email,
      scenerio: scenerio,
    });
  }
};

export const AnalyticsChangeMembershipDate = async (newDate, user) => {
  if (ifProduction) {
    // // // console.log("AnalyticsChangeMembershipDate")
    // // // console.log(newDate)
    MixPanelTrackEvent("Changed Membership Shipping Date", {
      userID: decodeBase64(user.id),
      userEmail: user.email,
      newDate: newDate,
    });
  }
};

export const AnalyticsChangeMembershipFrequency = async (newFrequency, user) => {
  if (ifProduction) {
    // // // console.log("AnalyticsChangeMembershipFrequency")
    // // // console.log(newFrequency)
    MixPanelTrackEvent("Changed Membership Frequency", {
      userID: decodeBase64(user.id),
      userEmail: user.email,
      newFrequency: newFrequency,
    });
  }
};

// --------------------------
// REWARDS PROGRAM
// --------------------------
export const AnalyticsRedeemedReward = async () => {
  if (ifProduction) {
    // // // console.log("AnalyticsRedeemedReward")
  }
};
