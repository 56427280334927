import React from "react";
import styled from "styled-components";
import BottomFooter from "./BottomFooter";
import FooterContent from "./FooterContent";
import dynamic from "next/dynamic";
const SocialAndContact = dynamic(() => import("./SocialAndContact"));
import StandardScroller from "@components/common/scrollers/StandardScroller";

// Main Footer Container Styles
const FooterContainer = styled.footer`
  background: ${(props) => props.theme.colors.darkPrimary};

  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    padding: 20px 20px 40px 20px;
  }
`;

const FooterInnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 40px 40px 40px;
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    flex-direction: column;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    padding: 0px 0.5rem 1.5rem 0.5rem;
    flex-direction: column;
  }
`;

// Universal large footer on the bottom of every page
const Footer = ({ slimFooter }) => (
  <>
    <StandardScroller />
    {!slimFooter && (
      <FooterContainer>
        <FooterInnerContainer>
          <FooterContent />
          <SocialAndContact />
        </FooterInnerContainer>
      </FooterContainer>
    )}
    <BottomFooter />
  </>
);

export default Footer;
