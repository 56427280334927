
export function decodeBase64(s) {
    var e={},i,b=0,c,x,l=0,a,r='',w=String.fromCharCode,L=s.length;
    var A="ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    for(i=0;i<64;i++){e[A.charAt(i)]=i;}
    for(x=0;x<L;x++){
        c=e[s.charAt(x)];b=(b<<6)+c;l+=6;
        while(l>=8){((a=(b>>>(l-=8))&0xff)||(x<(L-2)))&&(r+=w(a));}
    }
    return r;
};

// Converts an into a range
export const generateRange = (start, end) => {
    const length = end - start;
    return Array.from({ length }, (_, i) => start + i);
};

// Takes in an arry and outputs an paginated version
export function paginateArray(arr, len) {
    var chunks = [],
        i = 0,
        n = arr.length;

    while (i < n) {
        chunks.push(arr.slice(i, (i += len)));
    }

    return chunks;
}

export function hasDuplicates(array) {
    var valuesSoFar = Object.create(null);
    for (var i = 0; i < array.length; ++i) {
        var value = array[i];
        if (value in valuesSoFar) {
            return true;
        }
        valuesSoFar[value] = true;
    }
    return false;
}


// Add days to a specific date
export function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}


// Adds a certain amount of months to a given date
export function addMonths(date, months) {
    var currentDate = date;
    return new Date(currentDate.setMonth(currentDate.getMonth() + months));
}

// Adds a certain amount of months to a given date
export function removeMonths(date, months) {
    var currentDate = date;
    return new Date(currentDate.setMonth(currentDate.getMonth() - months));
}

// Creates a delay
export const delay = ms => new Promise(res => setTimeout(res, ms))

export function monthDiff(dateFrom, dateTo) {
    return dateTo.getMonth() - dateFrom.getMonth() + 
        (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
}

// Convert to est date object
export function changeTimeZone(date, timeZone) {
    if (typeof date === 'string') {
      return new Date(
        new Date(date).toLocaleString('en-US', {
          timeZone,
        }),
      );
    }
  
    return new Date(
      date.toLocaleString('en-US', {
        timeZone,
      }),
    );
  }