import React from "react";
import styled from "styled-components";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { cartObjectState } from "@selectors/cart";
import { cartDockState } from "@atoms/cartDock";
import NakedButton from "@components/common/buttons/NakedButton";

const CartIconContainer = styled.div`
  /* position: relative; */
  & button {
    transition: all 200ms ease-in-out;
    height: 33px;
    width: 33px;
    position: absolute;
    right: 20px;
    bottom: -4px;
    display: block;
  }
  :hover {
    & button {
      opacity: 0.7;
      transition: all 200ms ease-in-out;
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    & button {
      right: 5px;
      top: -9px;
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    & button {
      right: 5px;
      top: -18px;
    }
  }
`;

const CartQuantity = styled.p`
  width: 18px;
  height: 18px;
  position: absolute;
  top: -8px;
  right: -20px;
  background: white;
  text-align: center;
  z-index: 999;
  color: ${(props) => props.theme.colors.darkPrimary};
  border: 1px solid ${(props) => props.theme.colors.darkPrimary};
  border-radius: 1000px;
  font-weight: 500;
  padding: 2px 3px 4.5px 3px;
  margin: 0px;
  font-size: 1.7rem;
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    right: -16px;
    top: -9px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    font-size: 1.7rem;
    padding: 1px 2px 2px 2px;
    top: -7px;
    right: -12px;
  }
`;

const CartIconSVG = styled.img`
  width: 37px;

  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    width: 35px;
  }
`;

function CartIcon() {
  const cartObject = useRecoilValue(cartObjectState);
  console.log(cartObject);
  console.log(cartObject);
  const setCartDockOpen = useSetRecoilState(cartDockState);

  return (
    <>
      <CartIconContainer>
        <NakedButton onClick={() => setCartDockOpen(true)}>
          {cartObject && cartObject.cartQuantity > 0 && <CartQuantity>{cartObject.cartQuantity}</CartQuantity>}
          <CartIconSVG
            width="37px"
            height="34px"
            alt="Cart"
            src="https://images.ctfassets.net/1s5kub6totmj/2CkRgCAMzKtOs6up1xhNW/8d31ab5557462045dd98c89fd3931497/Cart-2.svg"
          />
        </NakedButton>
      </CartIconContainer>
    </>
  );
}

export default CartIcon;
