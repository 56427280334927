import React from "react";
import styled from "styled-components";
import BasicLink from "@common/links/BasicLink";

const LinkContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ColumnTitle = styled.p`
  color: #fff;
  font-family: ${(props) => props.theme.fonts.paragraph};
  font-weight: 700;
  font-size: 1.7rem;
  text-transform: uppercase;
  margin-bottom: 15px;
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    margin-top: 30px;
    margin-bottom: 20px;
  }
`;

const LinkContentColumn = styled.div`
  padding-right: 30px;
  & a {
    color: ${(props) => props.theme.colors.white};
    width: fit-content;
    color: white;
    font-size: 1.65rem;
    font-weight: 500;
    display: block;
    opacity: 0.8;
    line-height: 38px;
    transition: ${(props) => props.theme.transitions.standard};
    :hover {
      opacity: 1;
    }
  }
`;

// About Us Products More
// Small sub page links as well some main blog article links
const FooterContent = ({}) => (
  <LinkContentContainer>
    <LinkContentColumn>
      <ColumnTitle>About Us</ColumnTitle>
      <BasicLink
        href={"https://jointhe.cleancult.com/plasticintervention"}
        content="AMBASSADOR PROGRAM"
        realLink={true}
      />
      <BasicLink href={"/refill-ritual"} content="REFILL RITUAL" />
      <BasicLink href={"/sustainability"} content="SUSTAINABILITY" />
      <BasicLink href={"/store-locator"} content="STORES" />
      <BasicLink href={"/help"} content="FAQs" />
    </LinkContentColumn>
    <LinkContentColumn>
      <ColumnTitle>CONTACT US</ColumnTitle>
      <a color="white" href="mailto:hello@cleancult.com">
        HELLO@CLEANCULT.COM
      </a>
      <a color="white" href="tel:833-437-3363">
        (833) 437 3363
      </a>
    </LinkContentColumn>
  </LinkContentContainer>
);

export default FooterContent;
