import axios from "axios";

const shopify = axios.create({
  baseURL: process.env.SHOPIFY_STORE_FRONT_URL,
});
shopify.defaults.headers.common["X-Shopify-Storefront-Access-Token"] = process.env.SHOPIFY_STORE_FRONT_TOKEN;
shopify.defaults.headers.common["Content-Type"] = "application/json";

export const translateLoginUserErrors = (code) => {
  const codeMessages = {
    ALREADY_ENABLED: "Customer already enabled.",
    BAD_DOMAIN: "Input email contains an invalid domain name.",
    BLANK: "The input value is blank.",
    CONTAINS_HTML_TAGS: "Input contains HTML tags.",
    CONTAINS_URL: "Input contains URL.",
    CUSTOMER_DISABLED: "Your account is disabled.",
    INVALID: "The input value is invalid.",
    INVALID_MULTIPASS_REQUEST: "Multipass token is not valid.",
    NOT_FOUND: "Address does not exist.",
    PASSWORD_STARTS_OR_ENDS_WITH_WHITESPACE: "Your password starts or ends with whitespace.",
    TAKEN: "The input value is already taken.",
    TOKEN_INVALID: "Invalid activation token.",
    TOO_LONG: "The input value is too long.",
    TOO_SHORT: "The input value is too short.",
    UNIDENTIFIED_CUSTOMER: "Invalid email or password",
  };

  return codeMessages[code];
};

export const translateResetPasswordUserErrors = (code) => {
  const codeMessages = {
    ALREADY_ENABLED: "This account has already been registered. Please login to continue.",
    BAD_DOMAIN: "Input email contains an invalid domain name.",
    BLANK: "The input value is blank.",
    CONTAINS_HTML_TAGS: "Input contains HTML tags.",
    CONTAINS_URL: "Input contains URL.",
    CUSTOMER_DISABLED: "Your account is disabled.",
    INVALID: "The input value is invalid.",
    INVALID_MULTIPASS_REQUEST: "Multipass token is not valid.",
    NOT_FOUND: "Address does not exist.",
    PASSWORD_STARTS_OR_ENDS_WITH_WHITESPACE: "Your password starts or ends with whitespace.",
    TAKEN: "The input value is already taken.",
    TOKEN_INVALID: "Invalid activation token.",
    TOO_LONG: "The input value is too long.",
    TOO_SHORT: "The input value is too short.",
    UNIDENTIFIED_CUSTOMER: "User email does not exist in our system",
  };

  return codeMessages[code];
};

export const signOutUser = async (accessToken) => {
  return await shopify({
    method: "post",
    data: {
      query: `mutation customerAccessTokenDelete($customerAccessToken: String!) {
                customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
                    deletedAccessToken
                    deletedCustomerAccessTokenId
                    userErrors {
                      field
                      message
                    }
                }
            }`,
      variables: {
        customerAccessToken: accessToken,
      },
    },
  })
    .then(({ data }) => {
      return data;
    })
    .catch((error) => {
      return null;
    });
};

export const fetchUser = async (accessToken) => {
  return await shopify({
    method: "post",
    data: {
      query: `query {
                customer(customerAccessToken: "${accessToken}") {
                    id
                    displayName
                    email
                    firstName
                    lastName
                    createdAt
                    defaultAddress {
                        id
                        address1
                        address2
                        city
                        firstName
                        lastName
                        zip
                        province
                        provinceCode
                        phone
                    }
                    rewardPoints: metafield(
                        namespace: "my_fields"
                        key: "reward_points"
                    ) {
                        value
                    }  
                }
            }`,
    },
  })
    .then(({ data }) => {
      // // console.log(data.data.customer)
      // https://www.base64encoder.io/
      return data.data.customer;
      // return {
      //     id: "Z2lkOi8vc2hvcGlmeS9DdXN0b21lci82MDIyNDQwMjg4NDM0",
      //     email: "Danielle.m.duhe@gmail.com",
      //     testCase: true
      // }
    })
    .catch((error) => {
      return null;
    });
};

export const loginUser = async (email, password, confirmPassword) => {
  return await shopify({
    method: "post",
    data: {
      query: `
            mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
                customerAccessTokenCreate(input: $input) {
                    customerAccessToken {
                        accessToken
                        expiresAt
                    }
                    customerUserErrors {
                        code
                        field
                        message
                    }
                }
            }
            `,
      variables: {
        input: {
          email: email,
          password: password,
        },
      },
    },
  })
    .then(({ data }) => {
      if (data.data.customerAccessTokenCreate.customerAccessToken) {
        if (confirmPassword) {
          return true;
        } else {
          const accessToken = data.data.customerAccessTokenCreate.customerAccessToken.accessToken;
          localStorage.setItem("accessToken", accessToken);
          return fetchUser(accessToken);
        }
      } else {
        return {
          error: true,
          message: translateLoginUserErrors(data.data.customerAccessTokenCreate.customerUserErrors[0].code),
        };
      }
    })
    .catch((error) => {
      return null;
    });
};

export const recoverUserPassword = async (email) => {
  return await shopify({
    method: "post",
    data: {
      query: `
                mutation customerRecover($email: String!) {
                    customerRecover(email: $email) {
                    customerUserErrors {
                        code
                        field
                        message
                    }
                }
            }
            `,
      variables: {
        email: email,
      },
    },
  })
    .then(({ data }) => {
      if (!data.data.customerRecover) {
        return data.errors[0].message;
      } else {
        if (data.data.customerRecover.customerUserErrors[0]) {
          return {
            error: true,
            message: translateResetPasswordUserErrors(data.data.customerRecover.customerUserErrors[0].code),
          };
        } else {
          return { error: false };
        }
      }
    })
    .catch((error) => {
      return null;
    });
};

export const resetUserPasswordWithUrl = async (password, resetURL) => {
  return await shopify({
    method: "post",
    data: {
      query: `mutation customerResetByUrl($resetUrl: URL!, $password: String!) {
                    customerResetByUrl(resetUrl: $resetUrl, password: $password) {
                    customer {
                        id
                        displayName
                        email
                        firstName
                        lastName
                        createdAt
                    }
                    customerAccessToken {
                        accessToken
                        expiresAt
                    }
                    customerUserErrors {
                        code
                        field
                        message
                    }
                    }
                }
            `,
      variables: {
        resetUrl: resetURL,
        password: password,
      },
    },
  })
    .then(({ data }) => {
      if (!data.data.customerResetByUrl.customer) {
        return { error: true, message: data.data.customerResetByUrl.customerUserErrors[0].message };
      } else {
        localStorage.setItem("accessToken", data.data.customerResetByUrl.customerAccessToken.accessToken);
        return { user: data.data.customerResetByUrl.customer };
      }
    })
    .catch((error) => {
      return null;
    });
};

export const renewUserAccessToken = async (accessToken) => {
  return await shopify({
    method: "post",
    data: {
      query: `mutation customerAccessTokenRenew($customerAccessToken: String!) {
                    customerAccessTokenRenew(customerAccessToken: $customerAccessToken) {
                    customerAccessToken {
                        accessToken
                        expiresAt
                    }
                    userErrors {
                        field
                        message
                    }
                    }
                }
            `,
      variables: {
        customerAccessToken: accessToken,
      },
    },
  })
    .then(({ data }) => {
      if (data.data.customerAccessTokenRenew) {
        localStorage.setItem("accessToken", data.data.customerAccessTokenRenew.customerAccessToken.accessToken);
      }
    })
    .catch((error) => {
      return null;
    });
};

export const sendCustomerActivationEmail = async (email) => {
  return await shopify({
    method: "post",
    data: {
      query: `mutation customerCreate($input: CustomerCreateInput!) {
                customerCreate(input: $input) {
                    customer {
                        id
                    }
                    customerUserErrors {
                        code
                        field
                        message
                    }
                }
            }`,
      variables: {
        input: {
          email: email,
          password: email,
        },
      },
    },
  })
    .then(({ data }) => {
      return null;
    })
    .catch((error) => {
      return null;
    });
};

export const registerAccount = async (password, activationURL) => {
  return await shopify({
    method: "post",
    data: {
      query: `
                mutation customerActivateByUrl($activationUrl: URL!, $password: String!) {
                    customerActivateByUrl(activationUrl: $activationUrl, password: $password) {
                    customer {
                        id
                        displayName
                        email
                        firstName
                        lastName
                        createdAt
                    }
                    customerAccessToken {
                        accessToken
                        expiresAt
                    }
                    customerUserErrors {
                        code
                        field
                        message
                    }
                    }
                }
            `,
      variables: {
        password: password,
        activationUrl: activationURL,
      },
    },
  })
    .then(({ data }) => {
      if (!data.data.customerActivateByUrl.customer) {
        return {
          error: true,
          message: translateResetPasswordUserErrors(data.data.customerActivateByUrl.customerUserErrors[0].code),
        };
      } else {
        localStorage.setItem("accessToken", data.data.customerActivateByUrl.customerAccessToken.accessToken);
        return { user: data.data.customerActivateByUrl.customer };
      }
    })
    .catch((error) => {
      return null;
    });
};

// Only for first time registration
export const requestAccountAccountAcivation = async (email) => {
  return await axios({
    method: "post",
    url: "/api/auth/request-account-registration",
    data: {
      email: email,
    },
  })
    .then(({ data }) => {
      return data;
    })
    .catch(({ response }) => {
      return response.data.error;
      return null;
    });
};

// Only for first time registration
export const requestMultiPassLink = async (checkoutURL) => {
  return await axios({
    method: "post",
    url: "/api/auth/create-multi-pass-link",
    data: {
      accessToken: localStorage.getItem("accessToken"),
      checkoutURL: checkoutURL,
    },
  })
    .then(({ data }) => {
      return data.redirectURL;
    })
    .catch(({ response }) => {
      return checkoutURL;
    });
};
