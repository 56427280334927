import React, { useEffect, useRef } from "react";
import styled from "styled-components";

import Navigation from "@components/navigation";
import Footer from "@components/footer";

import { withRouter } from "next/router";
import { useSetRecoilState, useRecoilState } from "recoil";
import { cartObjectState } from "@selectors/cart";
import { inventoryState } from "@atoms/inventory";
import { userState } from "@atoms/user";
import { createCart, fetchCart } from "@helpers/cart";
import { fetchUser, renewUserAccessToken } from "@helpers/auth";
import Script from "next/script";
import getProductInventory from "@utils/productInventory";
import { AnalyticsTagsAndPageViews } from "@helpers/analytics";

const LayoutWrapper = styled.div`
  width: 100%;
  background: #fff;
  position: relative;
`;

const MainContentWrapper = styled.main`
  min-height: 87.5vh;
  background: ${(props) => props.backgroundColor && props.backgroundColor};
`;

function Layout({ children, router }) {
  const setCart = useSetRecoilState(cartObjectState);
  const [user, setUser] = useRecoilState(userState);
  const setProductInventoryDict = useSetRecoilState(inventoryState);
  const firstUpdate = useRef(true);

  const checkForCart = async () => {
    const cartID = localStorage.getItem("cartID");
    if (!cartID) {
      const cartObject = await createCart();
      setCart(cartObject);
      localStorage.setItem("cartID", cartObject.id);
    } else {
      const cartObject = await fetchCart(cartID);

      if (cartObject) {
        setCart(cartObject);
      } else {
        const cartObject = await createCart();
        setCart(cartObject);
        localStorage.setItem("cartID", cartObject.id);
      }
    }
  };

  const setProductInventory = async () => {
    const inventory = await getProductInventory();

    setProductInventoryDict(inventory);
  };

  const observeAuth = async () => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      const user = await fetchUser(accessToken);
      if (user) {
        setUser(user);
        renewUserAccessToken(accessToken);

        // Set the anlutics email so first runs can do this in the future
        localStorage.setItem("analyticsEmail", user.email);
      } else {
        setUser(null);
        localStorage.removeItem("accessToken");
      }
    } else {
      setUser(null);
    }
  };

  useEffect(() => {
    AnalyticsTagsAndPageViews();
    checkForCart();
    setProductInventory();
    observeAuth(); // also fires the global anlytics hit for logged in users or not
  }, []);

  useEffect(() => {
    if (!firstUpdate.current) {
      AnalyticsTagsAndPageViews(user);
    } else {
      firstUpdate.current = false;
    }
  }, [router.asPath]);

  const getSlimFooter = (path) => {
    if (path.includes("profile") || path.includes("update-payment-method")) {
      return true;
    } else {
      return false;
    }
  };

  const getSlimHeader = (path) => {
    if (path.includes("update-payment-method")) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <LayoutWrapper>
      <Navigation slimHeader={getSlimHeader(router.asPath)} />
      <MainContentWrapper id="mainContent">{children}</MainContentWrapper>
      <Script
        src="https://cdn.kustomerapp.com/chat-web/widget.js"
        data-kustomer-api-key="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxODJhYTUzMGNlYzVjMTM0ODVkNjg2NCIsInVzZXIiOiI2MTgyYWE0ZWY3MjExNDE2Mzc5MGI4NTMiLCJvcmciOiI1ZDM3MDU5MmIyZjgyMjAwMWExZjk0N2IiLCJvcmdOYW1lIjoiY2xlYW5jdWx0IiwidXNlclR5cGUiOiJtYWNoaW5lIiwicG9kIjoicHJvZDEiLCJyb2xlcyI6WyJvcmcudHJhY2tpbmciXSwiYXVkIjoidXJuOmNvbnN1bWVyIiwiaXNzIjoidXJuOmFwaSIsInN1YiI6IjYxODJhYTRlZjcyMTE0MTYzNzkwYjg1MyJ9.HIRCQUa54yBg6yeHOT-fUrNSFrWbtRWgJGDrZucvzOE"
        strategy="lazyOnload"
        onLoad={() => {
          Kustomer.start();
        }}
      />
      <Footer slimFooter={getSlimFooter(router.asPath)} />
    </LayoutWrapper>
  );
}

export default withRouter(Layout);
