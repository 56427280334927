import styled from "styled-components";
import { applyStyleModifiers } from "styled-components-modifiers";
import ActiveLink from "@components/common/links/ActiveLink";

const NAVIGATION_LINK_MODIFER_CONFIG = {
  active: ({ theme }) => `
        color: ${theme.colors.primary};
        border-bottom: 3px solid ${theme.colors.lightPrimary}
    `,
};

const LinkStyle = styled.a`
  color: ${(props) => props.theme.colors.darkPrimary};
  font-family: ${(props) => props.theme.fonts.paragraph};
  font-weight: 600;
  margin: 0px 19px;
  transition: ${(props) => props.theme.transitions.standard};
  letter-spacing: 0.01em;
  line-height: 46px;
  font-size: 1.7rem;
  text-transform: uppercase;
  text-decoration: none;
  border-bottom: 3px solid transparent;
  border-top: 3px solid transparent;
  ${applyStyleModifiers(NAVIGATION_LINK_MODIFER_CONFIG)};
  :hover {
    transition: ${(props) => props.theme.transitions.standard};
    opacity: 0.8;
    border-bottom: 3px solid ${(props) => props.theme.colors.lightPrimary};
  }
  @media (max-width: ${(props) => props.theme.breakPoints.xl}) {
    font-size: 1.5rem;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    margin: 0px 8px 0px 8px;
    font-size: 1.4rem;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    white-space: nowrap;
    margin: 0px 18px 0px 0px;
    line-height: 36px;
    font-weight: 600;
  }
`;

// Strict associate is there becuase if you do a loose associate, then the [id] and [productCategories] will not trigger active
const ProductNavLink = (props) => (
  <ActiveLink href={props.href} as={props.as} passHref looseAssociation={true}>
    <LinkStyle>{props.children}</LinkStyle>
  </ActiveLink>
);

export default ProductNavLink;
