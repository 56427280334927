import App from "next/app";
import React from "react";
import { ThemeProvider } from "styled-components";
import { DefaultSeo } from "next-seo";
import theme from "../styles/theme";
import Head from "next/head";
import NextNprogress from "nextjs-progressbar";
import Script from "next/script";
import Layout from "@containers/Layout";

import { toast, Slide } from "react-toastify";

import { RecoilRoot } from "recoil";

// Globally import React Toastify CSS
import "react-toastify/dist/ReactToastify.min.css";

toast.configure({
  autoClose: 4000,
  transition: Slide,
  draggable: false,
  progress: false,
  pauseOnHover: false,
  hideProgressBar: true,
  closeButton: false,
  limit: 1,
  closeOnClick: true,
  position: "top-center",
  progressStyle: {
    opacity: 0.4,
  },
  pauseOnFocusLoss: false,
});

// Font awesome
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;

class MyApp extends App {
  componentDidMount() {
    // Fixes the scroll jump issue when going to pages of different heights
    window.history.scrollRestoration = "manual";

    // If the user uses a keyboard then override the no outline styling
    window.addEventListener("keydown", this._handleKeydown);
  }

  _handleKeydown(e) {
    // Detect a keyboard user from a tab key press
    const isTabEvent = e.keyCode === 9;

    if (isTabEvent) {
      document.body.classList.add("using-keyboard");
    }
  }

  render() {
    const { Component, pageProps } = this.props;
    return (
      <>
        <Head>
          <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=5.0" key="viewport" />
          <link rel="preconnect" href="https://images.contentful.com" />
          <link rel="dns-prefetch" href="https://images.contentful.com" />
          <link rel="preconnect" href="https://cleancult-production-static.s3.amazonaws.com" />
          <link rel="dns-prefetch" href="https://cleancult-production-static.s3.amazonaws.com" />
        </Head>

        {/* ANALYTICS */}

        {/* ShareASale */}
        <Script src="https://www.dwin1.com/64666.js" strategy="afterInteractive" />

        {/* Attantive */}
        {/* <Script
          src="https://cdn.attn.tv/cleancult/dtag.js"
          strategy="afterInteractive"
        /> */}

        {/* Facebook */}
        <Script
          strategy="afterInteractive"
          id="facebook-init"
          dangerouslySetInnerHTML={{
            __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '2157432707813995');
                window.FB_INITIALIZED = true
              `,
          }}
        />

        {/* Google Analytics */}
        <Script
          strategy="afterInteractive"
          id="google-init"
          dangerouslySetInnerHTML={{
            __html: `
              (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){ (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o), m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m) })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
              ga('create', 'UA-75419671-1', 'auto', {'allowLinker': true});
              ga('require', 'linker');
              ga('linker:autoLink', ['checkout.cleancult.com'] );
              window.GA_INITIALIZED = true;
              `,
          }}
        />

        {/* Rakuten */}
        <Script
          strategy="afterInteractive"
          id="rakuten-init"
          dangerouslySetInnerHTML={{
            __html: `
                (function (url) {
                  /*Tracking Bootstrap Set Up DataLayer objects/properties here*/
                  if(!window.DataLayer){
                      window.DataLayer = {};
                  }
                  if(!DataLayer.events){
                      DataLayer.events = {};
                  }
                  DataLayer.events.SPIVersion = DataLayer.events.SPIVersion || "3.4.1";
                  DataLayer.events.SiteSection = "1";
          
                  var loc, ct = document.createElement("script");
                  ct.type = "text/javascript";
                  ct.async = true; ct.src = url; loc = document.getElementsByTagName('script')[0];
                  loc.parentNode.insertBefore(ct, loc);
                  }("https://tag.rmp.rakuten.com/123979.ct.js"));
              `,
          }}
        />

        {/* Google Adwords */}
        <Script strategy="afterInteractive" src={`https://www.googletagmanager.com/gtag/js?id=AW-795392202`} />
        <Script
          id="gtag-init"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('set', 'linker', {
                      'domains': ['www.cleancult.com', 'checkout.cleancult.com']
                  });
                  gtag('js', new Date());
                  gtag('config', 'AW-795392202', { 'allow_enhanced_conversions': true });
                `,
          }}
        />

        {/* TikTock */}
        <Script
          strategy="afterInteractive"
          id="tik-tock-init"
          dangerouslySetInnerHTML={{
            __html: `
                !function (w, d, t) {
                  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
              
                  ttq.load('C3DJ3187M4VETA97VPIG');
                  ttq.page();
              }(window, document, 'ttq');
              window.TT_INITIALIZED = true;
              `,
          }}
        />

        <ThemeProvider theme={theme}>
          <>
            <DefaultSeo
              // Basic Information
              title="More Power. Less Plastic."
              titleTemplate="%s  | Cleancult"
              description="We believe in refilling, not landfilling. Switch from a bad plastic habit to a cleaner refill ritual- one that cleans our homes and leaves them smelling heavenly without the plastic waste."
              // Twitter
              twitter={{
                cardType: "summary_large_image",
                site: "https://www.cleancult.com",
              }}
              // Open Graph
              openGraph={{
                type: "website",
                locale: "en_IE",
                url: "https://www.cleancult.com/",
                site_name: "Cleancult",
                ogImage: {
                  url: "/images/icons/share.png",
                },
                title: "More Power. Less Plastic.",
                description:
                  "We believe in refilling, not landfilling. Switch from a bad plastic habit to a cleaner refill ritual- one that cleans our homes and leaves them smelling heavenly without the plastic waste.",
              }}
            />
            <NextNprogress
              color="#ECF2F7"
              options={{ showSpinner: false }}
              startPosition={0.3}
              stopDelayMs={0}
              height={3}
            />
            <RecoilRoot>
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </RecoilRoot>
            <style jsx="true" global>{`
              // Global fonts
              @font-face {
                font-family: "Nazare";
                src: url("https://cleancult-production-static.s3.amazonaws.com/fonts/BrandonText/NazareCondensed-ExtraBold.woff2")
                  format("woff2"); /* Modern Browsers */
                font-weight: 600;
                font-style: normal;
                font-display: block;
              }

              @font-face {
                font-family: "Brandon Text";
                src: url("https://cleancult-production-static.s3.amazonaws.com/fonts/BrandonText/HVD+Fonts+-+BrandonText-Regular.woff2")
                  format("woff2"); /* Modern Browsers */
                font-weight: 400;
                font-style: normal;
                font-display: block;
              }
              @font-face {
                font-family: "Brandon Text";
                src: url("https://cleancult-production-static.s3.amazonaws.com/fonts/BrandonText/HVD+Fonts+-+BrandonText-Medium.woff2")
                  format("woff2"); /* Modern Browsers */
                font-weight: 500;
                font-style: normal;
                font-display: block;
              }
              @font-face {
                font-family: "Brandon Text";
                src: url("https://cleancult-production-static.s3.amazonaws.com/fonts/BrandonText/HVD+Fonts+-+BrandonText-Bold.woff2")
                  format("woff2"); /* Modern Browsers */
                font-weight: 600;
                font-style: normal;
                font-display: block;
              }
              @font-face {
                font-family: "Brandon Text";
                src: url("https://cleancult-production-static.s3.amazonaws.com/fonts/BrandonText/HVD+Fonts+-+BrandonText-Black.woff2")
                  format("woff2"); /* Modern Browsers */
                font-weight: 700;
                font-style: normal;
                font-display: block;
              }
            `}</style>
          </>
        </ThemeProvider>
      </>
    );
  }
}

export default MyApp;
