import styled from "styled-components";
import Marquee from "react-fast-marquee";
import React from "react";

const SliderBlock = styled.div`
  background: ${(props) => props.theme.colors.white};
  padding: 4px 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  & p {
    color: ${(props) => props.theme.colors.darkPrimary};
    text-transform: uppercase;
    font-size: 1.82rem;
    letter-spacing: 1.17px;
    font-weight: 800;
    margin: 0px;
    padding: 8px 5px;
    margin-left: 4px;
  }
  & img {
    margin-bottom: -4px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    min-width: 190px;
  }
`;

function StandardScroller() {
  const TitleBlock = (title) => {
    return (
      <SliderBlock>
        <p>{title}</p>
      </SliderBlock>
    );
  };

  const DingBlock = () => {
    return (
      <SliderBlock minWidth={"100px"}>
        <p>
          <img src="/images/icons/dings/blue/ding.svg" alt="Ding" width={20} />
        </p>
      </SliderBlock>
    );
  };

  return (
    <Marquee gradient={false} speed={100} loop={0}>
      {TitleBlock("JOIN THE CLEANCULT")}
      {DingBlock()}
      {TitleBlock("BE A NO-PLASTIC FANATIC")}
      {DingBlock()}
      {TitleBlock("JOIN THE CLEANCULT")}
      {DingBlock()}
      {TitleBlock("BE A NO-PLASTIC FANATIC")}
      {DingBlock()}
      {TitleBlock("JOIN THE CLEANCULT")}
      {DingBlock()}
      {TitleBlock("BE A NO-PLASTIC FANATIC")}
      {DingBlock()}
      {TitleBlock("JOIN THE CLEANCULT")}
      {DingBlock()}
      {TitleBlock("BE A NO-PLASTIC FANATIC")}
      {DingBlock()}
      {TitleBlock("JOIN THE CLEANCULT")}
      {DingBlock()}
      {TitleBlock("BE A NO-PLASTIC FANATIC")}
      {DingBlock()}
    </Marquee>
  );
}

export default StandardScroller;
