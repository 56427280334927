import { inventoryState } from "@atoms/inventory";
import { selector } from "recoil";

// HELPER
export const convertInventoryIntoDictByKey = (productInventory, key) => {
  const inventoryDict = {};
  if (productInventory) {
    productInventory.map(function (productObject) {
      inventoryDict[productObject[key]] = productObject;
      return productObject;
    });
  }
  return inventoryDict;
};

export const shopifyInventoryDictState = selector({
  key: "shopifyInventoryDictState",
  get: ({ get }) => {
    const productInventory = get(inventoryState);
    const inventoryDict = convertInventoryIntoDictByKey(
      productInventory,
      "shopifyGlobalId"
    );
    return inventoryDict;
  },
  set: ({ set }, newValue) => set(inventoryState, newValue),
});

export const shopifyAdminInventoryDictState = selector({
  key: "shopifyAdminInventoryDictState",
  get: ({ get }) => {
    const productInventory = get(inventoryState);
    const inventoryDict = convertInventoryIntoDictByKey(
      productInventory,
      "shopifyAdminID"
    );
    return inventoryDict;
  },
  set: ({ set }, newValue) => set(inventoryState, newValue),
});

export const cleancultInventoryDictState = selector({
  key: "cleancultInventoryDictState",
  get: ({ get }) => {
    const productInventory = get(inventoryState);
    const inventoryDict = convertInventoryIntoDictByKey(
      productInventory,
      "cleancultID"
    );
    return inventoryDict;
  },
  set: ({ set }, newValue) => set(inventoryState, newValue),
});
