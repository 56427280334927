import React from "react";
import styled from "styled-components";
import onClickOutside from "react-onclickoutside";
import Image from "next/image";
import Link from "next/link";
import { UnmountClosed } from "react-collapse";

const DesktopProductNavigationWrapper = styled.div`
  position: absolute;
  background: white;
  top: 70px;
  left: 0px;
  z-index: 100;
  width: 100%;
  transition: visibility 0.3s ease, height 0.2s, opacity 0.4s ease;
  border-bottom: 1px solid ${(props) => props.theme.colors.extraLightPrimary};
  box-shadow: ${(props) => props.theme.boxShadow.blurred};
  opacity: ${(props) => (props.visible ? "1" : "0")};
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    height: ${(props) => (props.visible ? "180px" : "0px")};
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    display: none;
  }
`;

const InnerNavigation = styled.div`
  max-width: 1000px;
  padding: 0px 16px;
  margin: auto;
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  a:last-child {
    border: 0px !important;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.lg}) {
    padding: 0px 5px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    height: 160px;
  }
`;

const ProductLink = styled.a`
  display: block;
  padding: 0px 13px;
  border-right: 1px solid ${(props) => props.theme.colors.extraLightPrimary};
  text-align: center;
  transition: all 0.3s ease;
  & img {
    margin-top: 0px !important;
  }
  & p {
    font-weight: 700;
    font-size: 1.5rem;
    text-transform: uppercase;
    margin: 0px;
    color: ${(props) => props.theme.colors.darkPrimary};
    transition: all 0.3s ease;
  }
  :hover {
    & p {
      transition: all 0.3s ease;
      color: ${(props) => props.theme.colors.lightPrimary};
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.lg}) {
    padding: 0px 10px;
    & p {
      font-size: 1.7rem;
    }
  }
`;

class DesktopProductNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openCount: 0,
    };
  }

  handleClickOutside = (evt) => {
    if (this.props.desktopProductMenuOpen && !evt.target.id.includes("shopButton")) {
      this.props.toggleDesktopProductMenuOpen();
    }
  };

  render() {
    return (
      <DesktopProductNavigationWrapper visible={this.props.desktopProductMenuOpen}>
        <UnmountClosed isOpened={this.props.desktopProductMenuOpen}>
          <InnerNavigation>
            <Link href="/products/shop/hand-soaps" passHref>
              <ProductLink>
                <Image
                  alt={" Hand Soaps"}
                  src={
                    "https://images.ctfassets.net/1s5kub6totmj/6tgdAZGClKfUiW8jZsXOwK/1c742dd68968204649e6eca4038464a3/Hand_Soap.png"
                  }
                  width={140}
                  height={140}
                />
                <p>Hand Soap</p>
              </ProductLink>
            </Link>
            <Link href="/products/shop/cleaning-sprays" passHref>
              <ProductLink>
                <Image
                  alt={" Sprays"}
                  src={
                    "https://images.ctfassets.net/1s5kub6totmj/5oDylsJuI32oqpNGeQK1sH/a106e08aff926498be80fe21fe57d58b/All_Purpose.png"
                  }
                  width={140}
                  height={140}
                />
                <p>All Purpose Cleaner</p>
              </ProductLink>
            </Link>
            <Link href="/products/shop/laundry" passHref>
              <ProductLink>
                <Image
                  alt={" Laundry"}
                  src={
                    "https://images.ctfassets.net/1s5kub6totmj/176QQEbdO67gM4zHywFPEa/e1f025cac5fb145bc8d1247e2d248ec7/Laundry.png"
                  }
                  width={140}
                  height={140}
                />
                <p>Laundry</p>
              </ProductLink>
            </Link>
            <Link href="/products/shop/dish" passHref>
              <ProductLink>
                <Image
                  alt={" Dish Soap & Tablets"}
                  src={
                    "https://images.ctfassets.net/1s5kub6totmj/4PZN3Qq5xGIn3ymrhE1I41/ce7946a6b9e4f12f15aa48ef191c900e/Dish_Soap.png"
                  }
                  width={140}
                  height={140}
                />
                <p>Dish Soap</p>
              </ProductLink>
            </Link>
            <Link href="/products/shop/bar-soap" passHref>
              <ProductLink>
                <Image
                  alt={" Bar Soap"}
                  src={
                    "https://images.ctfassets.net/1s5kub6totmj/2KtGI0xCvFN0XYpLOmoMer/08c6a5bf18b1f4c696c9e6454a4e7ff7/Bar_Soap.png"
                  }
                  width={140}
                  height={140}
                />
                <p>Bar Soaps</p>
              </ProductLink>
            </Link>
          </InnerNavigation>
        </UnmountClosed>
      </DesktopProductNavigationWrapper>
    );
  }
}

export default onClickOutside(DesktopProductNavigation);
