import React from 'react';
import { withRouter } from 'next/router';
import Router from 'next/router'
import dynamic from 'next/dynamic'
import NavigationHeader from "@components/navigation/NavigationHeader";
const MobileNavigation = dynamic(() => import("@components/navigation/MobileNavigation"))
const CartDock = dynamic(() => import("@components/cart"), {ssr: false})

class Navigation extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            mobileMenuOpen: false,
            productMenuOpen: false,
            desktopProductMenuOpen: false
        };
        this.toggleMobileMenuOpen = this.toggleMobileMenuOpen.bind(this);
        this.toggleProductMenuOpen = this.toggleProductMenuOpen.bind(this);
        this.toggleDesktopProductMenuOpen = this.toggleDesktopProductMenuOpen.bind(this);
        this.pushMobileLink = this.pushMobileLink.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.router.asPath !== state.path) {
            if (typeof document !== 'undefined') {
                return {
                    mobileMenuOpen: false,
                    path: props.router.asPath,
                    productMenuOpen: false,
                    desktopProductMenuOpen: false
                }
            }
        } 
        return null
    }



    // Toggles the mobile menu dropdown
    toggleMobileMenuOpen() {
        this.setState({
            mobileMenuOpen: !this.state.mobileMenuOpen,
            productMenuOpen: false,
            desktopProductMenuOpen: false
        })
        
    }

    pushMobileLink(path) {
        Router.push(path)
        this.setState({
            mobileMenuOpen: false
        })
    }

    toggleProductMenuOpen() {
        this.setState({
            productMenuOpen: !this.state.productMenuOpen
        })
    }

    toggleDesktopProductMenuOpen() {
        this.setState({
            desktopProductMenuOpen: !this.state.desktopProductMenuOpen
        })
    }
        

    getSlimHeader = (path) => {
        if (path.includes("shop")) {
            return true
        } else {
            return false
        }
    }

    render() {
        const { mobileMenuOpen, productMenuOpen, desktopProductMenuOpen }  = this.state;
        const { slimHeader }  = this.props;

        return (
            <>
                <NavigationHeader 
                    mobileMenuOpen={mobileMenuOpen} 
                    showProductRow={this.getSlimHeader(this.props.router.pathname)}
                    toggleMobileMenuOpen={this.toggleMobileMenuOpen}
                    productMenuOpen={productMenuOpen}
                    slimHeader={slimHeader}
                    toggleDesktopProductMenuOpen={this.toggleDesktopProductMenuOpen}
                    desktopProductMenuOpen={desktopProductMenuOpen}
                />
                {
                    !slimHeader && (
                        <>
                            <MobileNavigation
                                mobileMenuOpen={mobileMenuOpen} 
                                toggleProductMenuOpen={this.toggleProductMenuOpen}
                                productMenuOpen={productMenuOpen}
                                toggleMobileMenuOpen={this.toggleMobileMenuOpen}
                                pushMobileLink={this.pushMobileLink}
                            />
                            <CartDock
                                routerPath={this.props.router.asPath}
                            />
                        </>
                    )
                }
            </>
        )
    }
}

export default withRouter(Navigation);