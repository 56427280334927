import ContentfulClient from "@utils/contentfulClient";
import { decodeBase64 } from "@helpers/common";
import { subscribeAndSaveRate } from "@constants";

const retreiveContentfulProducts = async (urlKey, staging) => {
  const products = await ContentfulClient.getEntries({
    content_type: "product",
    order: "fields.productNameInternal",
    include: 10,
    "fields.urlKey": urlKey,
    "fields.active": staging ? null : true,
  });

  let formattedProductFeed = [];

  products.items.map(function (contentfulProduct) {
    // Define base layer product
    let formattedProductObject = {
      marketingName: contentfulProduct.fields.category.fields.name,
      price: contentfulProduct.fields.variation.fields.price,
      subscriptionPrice: contentfulProduct.fields.variation.fields.subscribable
        ? parseFloat(
            (
              contentfulProduct.fields.variation.fields.price -
              contentfulProduct.fields.variation.fields.price * (subscribeAndSaveRate / 100)
            ).toFixed(2)
          )
        : contentfulProduct.fields.variation.fields.price,
      compareAtPrice: null,
      shopifyID: contentfulProduct.fields.shopifyProduct,
      shopifyAdminID: decodeBase64(contentfulProduct.fields.shopifyProduct).split("/")[4],
      shopifyGlobalId: `gid://shopify/ProductVariant/${
        decodeBase64(contentfulProduct.fields.shopifyProduct).split("/")[4]
      }`,
      cleancultID: contentfulProduct.fields.productNameInternal,
      googleShoppingReviewID: contentfulProduct.fields.googleShoppingReviewIdOverride
        ? contentfulProduct.fields.googleShoppingReviewIdOverride
        : contentfulProduct.fields.productNameInternal,
      newRelease: contentfulProduct.fields.newRelease,
      // leadBundle: contentfulProduct.fields.leadBundle,
      bestSeller: contentfulProduct.fields.bestSeller,
      active: contentfulProduct.fields.active,
      hideOnSite: contentfulProduct.fields.productNameInternal.includes("rtu")
        ? false
        : contentfulProduct.fields.hideOnSite,
      urlKey: contentfulProduct.fields.urlKey,
      urlRedirects: contentfulProduct.fields.legacyUrlRedirects,
      reviews: contentfulProduct.fields.variation.fields.reviews,
      relatedProduct: null,
      callOutTags: null,
      scent: contentfulProduct.fields.scent && {
        name: contentfulProduct.fields.scent.fields.name,
        colorCode: contentfulProduct.fields.scent.fields.scentColor,
        description: contentfulProduct.fields.scent.fields.scentDescription,
        image: `https:${contentfulProduct.fields.scent.fields.image.fields.file.url}`,
        backgroundImage: contentfulProduct.fields.scent.fields.backgroundImage
          ? `https:${contentfulProduct.fields.scent.fields.backgroundImage.fields.file.url}`
          : null,
        heroColorOverlay: contentfulProduct.fields.scent.fields.scentHeroOverlayColor,
      },
      bottleColor: contentfulProduct.fields.bottleColor && {
        name: contentfulProduct.fields.bottleColor.fields.name,
        colorCode: contentfulProduct.fields.bottleColor.fields.colorCode,
      },
      variation: {
        contentfulID: contentfulProduct.fields.variation.sys.id,
        shopifyProductGroupID: contentfulProduct.fields.variation.fields.shopifyProductId,
        content: [],
        callOut: contentfulProduct.fields.variation.fields.callOut,
        careInstructions: contentfulProduct.fields.variation.fields.careInstructions,
        description: contentfulProduct.fields.variation.fields.description,
        endOfLife: contentfulProduct.fields.variation.fields.endOfLife,
        sellingPlans:
          contentfulProduct.fields.variation.fields.sellingPlan &&
          contentfulProduct.fields.variation.fields.sellingPlan.sellingPlans,
        friendlyProductSize: contentfulProduct.fields.variation.fields.friendlyProductSize,
        howToUse: contentfulProduct.fields.variation.fields.howToUse,
        internalName: contentfulProduct.fields.variation.fields.internalName,
        packageName: contentfulProduct.fields.variation.fields.packageName,
        packageNamePlural: contentfulProduct.fields.variation.fields.packageNamePlural,
        packagingAndMaterials: contentfulProduct.fields.variation.fields.packagingAndMaterials,
        productCategory: contentfulProduct.fields.variation.fields.productCategory,
        shopAllFilters: contentfulProduct.fields.variation.fields.shopAllFilters,
        subscribable: contentfulProduct.fields.variation.fields.subscribable,
        valueProps: [],
        scentVariations: null,
        bottleColorVariations: null,
        ingredients: null,
      },
      bundleProducts: null,
      images: {
        cartImage: `https:${contentfulProduct.fields.cartImage.fields.file.url}`,
        bottleSizingImage:
          contentfulProduct.fields.bottleSizeImage &&
          `https:${contentfulProduct.fields.bottleSizeImage.fields.file.url}`,
        listingImage: contentfulProduct.fields.listingImageNew
          ? `https:${contentfulProduct.fields.listingImageNew.fields.file.url}`
          : contentfulProduct.fields.listingImage
          ? `https:${contentfulProduct.fields.listingImage.fields.file.url}`
          : `https:${contentfulProduct.fields.cartImage.fields.file.url}`,
        listingImageHover: contentfulProduct.fields.listingImageHoverNew
          ? `https:${contentfulProduct.fields.listingImageHoverNew.fields.file.url}`
          : contentfulProduct.fields.listingImage
          ? `https:${contentfulProduct.fields.listingImage.fields.file.url}`
          : `https:${contentfulProduct.fields.cartImage.fields.file.url}`,
        heroImages: [],
      },
    };

    // Add in hero image
    if (contentfulProduct.fields.heroImagesNew) {
      contentfulProduct.fields.heroImagesNew.map(function (heroImageObject) {
        formattedProductObject.images.heroImages.push(`https:${heroImageObject.fields.file.url}`);
        return heroImageObject;
      });
    } else {
      contentfulProduct.fields.heroImages.map(function (heroImageObject) {
        formattedProductObject.images.heroImages.push(`https:${heroImageObject.fields.file.url}`);
        return heroImageObject;
      });
    }

    // Ingredients
    if (contentfulProduct.fields.variation.fields.ingredients) {
      formattedProductObject.variation.ingredients = [];
      contentfulProduct.fields.variation.fields.ingredients.map(function (ingredientObject) {
        formattedProductObject.variation.ingredients.push({
          name: ingredientObject.fields.name,
        });
        return ingredientObject;
      });
    }

    // Color Variations
    if (contentfulProduct.fields.variation.fields.colorVariations) {
      formattedProductObject.variation.bottleColorVariations = [];
      let colorVariations = contentfulProduct.fields.variation.fields.colorVariations;
      // if (contentfulProduct.fields.bundleColorVariations) {
      //   colorVariations = contentfulProduct.fields.bundleColorVariations;
      // }
      colorVariations.map(function (colorVariationObject) {
        formattedProductObject.variation.bottleColorVariations.push({
          cleancultID: colorVariationObject.fields.productNameInternal,
          variationID: colorVariationObject.fields.shopifyProduct,
          name: colorVariationObject.fields.bottleColor.fields.name,
          colorCode: colorVariationObject.fields.bottleColor.fields.colorCode,
          variationURLKey: colorVariationObject.fields.urlKey,
          active: colorVariationObject.fields.shopifyProduct === formattedProductObject.shopifyID,
        });
        return colorVariationObject;
      });
    }

    // Scent Variations
    if (contentfulProduct.fields.variation.fields.scentVariations) {
      formattedProductObject.variation.scentVariations = [];
      let scentVariations = contentfulProduct.fields.variation.fields.scentVariations;
      // if (contentfulProduct.fields.bundleScentVariations) {
      //   scentVariations = contentfulProduct.fields.bundleScentVariations;
      // }
      scentVariations.map(function (scentVariationObject) {
        formattedProductObject.variation.scentVariations.push({
          variationID: scentVariationObject.fields.shopifyProduct,
          cleancultID: scentVariationObject.fields.productNameInternal,
          variationURLKey: scentVariationObject.fields.urlKey,
          colorCode: scentVariationObject.fields.scent.fields.scentColor,
          name: scentVariationObject.fields.scent.fields.name,
          image: `https:${scentVariationObject.fields.scent.fields.image.fields.file.url}`,
          active: scentVariationObject.fields.shopifyProduct === formattedProductObject.shopifyID,
        });
        return scentVariationObject;
      });
    }

    // Bundle Products
    // if (contentfulProduct.fields.bundleProductGroups) {
    //   let bundleProducts = [];
    //   contentfulProduct.fields.bundleProductGroups.map(function (bundleProductGroupObject) {
    //     let tempBundleProductGroupObject = {
    //       cartImage: `https:${bundleProductGroupObject.fields.cartImage.fields.file.url}`,
    //       price: bundleProductGroupObject.fields.price,
    //       recurringOrder: bundleProductGroupObject.fields.recurringOrder,
    //       shopifyID: bundleProductGroupObject.fields.shopifyProduct,
    //       shopifyAdminID: decodeBase64(bundleProductGroupObject.fields.shopifyProduct).split("/")[4],
    //       bundleProducts: [],
    //     };

    //     bundleProductGroupObject.fields.bundleProducts.map(function (bundleProductObject) {
    //       tempBundleProductGroupObject.bundleProducts.push({
    //         shopifyAdminID: decodeBase64(bundleProductObject.fields.product.fields.shopifyProduct).split("/")[4],
    //         quantity: bundleProductObject.fields.quantity,
    //       });

    //       bundleProducts.push(bundleProductObject);
    //     });

    //     if (tempBundleProductGroupObject.recurringOrder) {
    //       formattedProductObject.bundleRefillsGroup = tempBundleProductGroupObject;
    //     } else {
    //       formattedProductObject.bundleGlassBottlesGroup = tempBundleProductGroupObject;
    //     }
    //   });

    //   formattedProductObject.bundleProducts = [];
    //   formattedProductObject.compareAtPrice = 0;

    //   bundleProducts.map(function (bundleProductObject) {
    //     // Add in the compare at price
    //     formattedProductObject.compareAtPrice +=
    //       bundleProductObject.fields.product.fields.variation.fields.price * bundleProductObject.fields.quantity;

    //     // Add in the products
    //     formattedProductObject.bundleProducts.push({
    //       cleancultID: bundleProductObject.fields.product.fields.productNameInternal,
    //       variationID: bundleProductObject.fields.product.fields.variation.sys.id,
    //       shopifyAdminID: decodeBase64(bundleProductObject.fields.product.fields.shopifyProduct).split("/")[4],
    //       marketingName: bundleProductObject.fields.product.fields.category.fields.name,
    //       image: `https:${bundleProductObject.fields.product.fields.cartImage.fields.file.url}`,
    //       urlKey: bundleProductObject.fields.product.fields.urlKey,
    //       id: bundleProductObject.fields.product.fields.shopifyProduct,
    //       quantity: bundleProductObject.fields.quantity,
    //       free: bundleProductObject.fields.free,
    //       callOut: bundleProductObject.fields.product.fields.variation.fields.callOut,

    //       scent: bundleProductObject.fields.product.fields.scent && {
    //         name: bundleProductObject.fields.product.fields.scent.fields.name,
    //         colorCode: bundleProductObject.fields.product.fields.scent.fields.scentColor,
    //         image: `https:${bundleProductObject.fields.product.fields.scent.fields.image.fields.file.url}`,
    //       },
    //       bottleColor: bundleProductObject.fields.product.fields.bottleColor && {
    //         name: bundleProductObject.fields.product.fields.bottleColor.fields.name,
    //         colorCode: bundleProductObject.fields.product.fields.bottleColor.fields.colorCode,
    //       },
    //     });
    //     return bundleProductObject;
    //   });
    //   formattedProductObject.compareAtPrice = formattedProductObject.compareAtPrice.toFixed(2);
    // }

    // Related Product
    // if (contentfulProduct.fields.variation.fields.relatedProduct) {
    //   formattedProductObject.relatedProduct =
    //     contentfulProduct.fields.variation.fields.relatedProduct.fields.shopifyProduct;
    // }

    // Bundle Up Sell
    // if (contentfulProduct.fields.variation.fields.bundleUpSell) {
    //   formattedProductObject.variation.bundleUpSell = {
    //     marketingName: contentfulProduct.fields.variation.fields.bundleUpSell.fields.category.fields.name,
    //     image: `https:${contentfulProduct.fields.variation.fields.bundleUpSell.fields.cartImage.fields.file.url}`,
    //     backgroundImage: `https:${contentfulProduct.fields.variation.fields.bundleUpSell.fields.listingImage.fields.file.url}`,
    //     urlKey: contentfulProduct.fields.variation.fields.bundleUpSell.fields.urlKey,
    //     id: contentfulProduct.fields.shopifyProduct,
    //   };
    // }

    // Call Out tags
    // BUNDLE
    if (formattedProductObject.compareAtPrice) {
      formattedProductObject.callOutTags = [
        {
          title: `Save ${parseInt(
            (formattedProductObject.price / formattedProductObject.compareAtPrice - 1) * 100 * -1
          )}%`,
          color: "#d3e4ff",
          productDetailsDisplay: true,
        },
      ];
    } else if (formattedProductObject.scent) {
      formattedProductObject.callOutTags = [
        {
          title: formattedProductObject.scent.name,
          color: formattedProductObject.scent.colorCode,
        },
      ];
    }

    if (formattedProductObject.newRelease) {
      if (!formattedProductObject.callOutTags) {
        formattedProductObject.callOutTags = [];
      }
      formattedProductObject.callOutTags.push({
        title: "New",
        color: null,
        productDetailsDisplay: true,
      });
    }

    if (formattedProductObject.bestSeller) {
      if (!formattedProductObject.callOutTags) {
        formattedProductObject.callOutTags = [];
      }
      formattedProductObject.callOutTags.push({
        title: "Best Seller",
        color: null,
        productDetailsDisplay: true,
      });
    }

    // Sub Headline Call Out
    if (formattedProductObject.scent) {
      formattedProductObject.variation.callOut = `${formattedProductObject.scent.name} | ${formattedProductObject.variation.callOut}`;
    } else if (formattedProductObject.bottleColor) {
      formattedProductObject.variation.callOut = `${formattedProductObject.bottleColor.name} | ${formattedProductObject.variation.callOut}`;
    }

    formattedProductFeed.push(formattedProductObject);

    // Product Content
    if (contentfulProduct.fields.variation.fields.content) {
      let contentCount = 0;

      let finalizedContent = [];
      contentfulProduct.fields.variation.fields.content.map(function (contentObject) {
        contentCount += 1;
        let finalContentObject = null;

        if (contentObject.sys.contentType.sys.id === "contentPreDefinedContent") {
          finalContentObject = {
            type: "preDefinedContent",
            content: contentObject.fields.type,
          };
        } else if (contentObject.sys.contentType.sys.id === "contentCallOutBlock") {
          finalContentObject = {
            type: "contentCallOutBlock",
            content: {
              lastItem: contentCount == contentfulProduct.fields.variation.fields.content.length,
              imagesObject: {
                desktop: `https:${contentObject.fields.desktopHero.fields.file.url}`,
                mobile: `https:${contentObject.fields.mobileImage.fields.file.url}`,
              },
              textObject: {
                color: contentObject.fields.textColor,
                title: contentObject.fields.title,
                description: contentObject.fields.description,
              },
              buttonObject: {
                title: contentObject.fields.buttonText,
                color: contentObject.fields.buttonColor,
                link: contentObject.fields.buttonLink,
              },
              marginBottom: contentObject.fields.marginBottom,
            },
          };
        } else if (contentObject.sys.contentType.sys.id === "contentCustomScrollingBanner") {
          finalContentObject = {
            type: "contentCustomScrollingBanner",
            content: {
              items: contentObject.fields.items,
              lastItem: contentCount == contentfulProduct.fields.variation.fields.content.length,
              iconOverride: contentObject.fields.iconOverride && contentObject.fields.iconOverride.fields.file.url,
              marginBottom: contentObject.fields.marginBottom,
              color: contentObject.fields.textColor,
              backgroundColor: contentObject.fields.bannerBackground,
            },
          };
        } else if (contentObject.sys.contentType.sys.id === "contentDualPanelHeroBlock") {
          finalContentObject = {
            type: "contentDualPanelHeroBlock",
            content: {
              text: contentObject.fields.text,
              image: contentObject.fields.image.fields.file.url,
              backgroundColor: contentObject.fields.backgroundColor,
              imagePosition: contentObject.fields.imagePosition,
            },
          };
        } else if (contentObject.sys.contentType.sys.id === "contentSinglePanelHeroBlock") {
          finalContentObject = {
            type: "contentSinglePanelHeroBlock",
            content: {
              title: contentObject.fields.title,
              subtitle: contentObject.fields.subtitle,
              textColor: contentObject.fields.textColor,
              backgroundImage: contentObject.fields.backgroundImage.fields.file.url,
              fullWidth: contentObject.fields.fullWidth,
              lastItem: contentCount == contentfulProduct.fields.variation.fields.content.length,
              marginBottom: contentObject.fields.marginBottom,
            },
          };
        } else if (contentObject.sys.contentType.sys.id === "contentTriplePanelHeroBlock") {
          finalContentObject = {
            type: "contentTriplePanelHeroBlock",
            content: {
              title: contentObject.fields.title,
              block1Image: contentObject.fields.block1Image.fields.file.url,
              block1Text: contentObject.fields.block1Text,
              block2Image: contentObject.fields.block2Image.fields.file.url,
              block2Text: contentObject.fields.block2Text,
              block3Image: contentObject.fields.block3Image.fields.file.url,
              block3Text: contentObject.fields.block3Text,
              backgroundColor: contentObject.fields.backgroundColor,
            },
          };
        } else if (contentObject.sys.contentType.sys.id === "contentCustomProductSlider") {
          finalContentObject = {
            type: "contentCustomProductSlider",
            content: {
              title: contentObject.fields.title,
              products: [
                contentObject.fields.product1,
                contentObject.fields.product2,
                contentObject.fields.product3,
                contentObject.fields.product4,
                contentObject.fields.product5,
                contentObject.fields.product6,
                contentObject.fields.product7,
                contentObject.fields.product8,
                contentObject.fields.product9,
              ],
            },
          };
        }

        if (finalContentObject) {
          finalizedContent.push(finalContentObject);
        }
        return contentObject;
      });

      formattedProductObject.variation.content = finalizedContent;
    }

    // Gift Card Overrides
    if (formattedProductObject.variation.productCategory === "gift_card") {
      let price = formattedProductObject.urlKey.split("-")[3];
      formattedProductObject.price = parseInt(price);
      formattedProductObject.variation.callOut = `$${price} | ${formattedProductObject.variation.callOut}`;
    }

    return contentfulProduct;
  });
  return formattedProductFeed;
};

let sortKey = {
  bundles: 5,
  refills: 2,
  unfilled_glass: 3,
  essentials: 4,
};

// Final product inventory push
const getProductInventory = async (urlKey, staging) => {
  let contentfulInventory = await retreiveContentfulProducts(urlKey, staging);
  contentfulInventory = JSON.parse(JSON.stringify(contentfulInventory));
  // // console.log(contentfulInventory);
  contentfulInventory.sort((a, b) => {
    return a.marketingName - b.marketingName;
  });
  contentfulInventory.sort((a, b) => {
    return sortKey[a.variation.productCategory] - sortKey[b.variation.productCategory];
  });
  contentfulInventory.sort((a, b) => {
    return b.newRelease - a.newRelease;
  });
  console.log(contentfulInventory);
  return contentfulInventory;
};

export default getProductInventory;
