import React from "react";
import styled from "styled-components";
import BasicLink from "@common/links/BasicLink";

const BottomFooterContainer = styled.footer`
  background: ${(props) => props.theme.colors.darkPrimary};
  padding: 20px 50px;
  margin: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  & a,
  p {
    margin: 0px 10px;
    font-weight: 500;
    font-size: 1.55rem;
    opacity: 0.7;
    color: ${(props) => (props.slimFooter ? "white" : "white")};
    display: block;
    line-height: 26px;
  }
  a {
    transition: ${(props) => props.theme.transitions.standard};
    :hover {
      opacity: 1;
    }
  }

  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    & a {
      margin: 0px 10px 10px 0px;
      line-height: 30px;
    }

    & p {
      margin: 0px 10px 10px 0px;
      line-height: 30px;
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    text-align: left;
    padding: 20px 20px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.xs}) {
    padding-bottom: 15px;
  }
`;

// Bottom legal footer for Terms and Accesabilyt notices
const BottomFooter = ({}) => (
  <BottomFooterContainer>
    <p color="primary">© 2023. Cleancult - All Rights Reserved.</p>
    <BasicLink href={"/legal/privacy-policy"} content="Privacy Policy" />
    <BasicLink
      href={"/legal/terms-and-conditions"}
      content="Terms & Conditions"
    />
    <BasicLink href={"/legal/accessibility"} content="Accessibility" />
    <BasicLink href={"/legal/prop-65"} content="Supply Chain Act" />
    <BasicLink href={"/legal/cookies"} content="Cookies" />
    {/* <BasicLink href={"/legal/conflict-minerals"} content="Conflict Minerals" /> */}
  </BottomFooterContainer>
);

export default BottomFooter;
