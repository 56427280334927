import React from "react";
import styled from "styled-components";

import ProductNavLink from "@components/navigation/links/ProductNavLink";

const ProductNavigationOuterWrapper = styled.div`
  position: sticky;
  top: 70px;
  transition: all 0.2s ease;
  z-index: 100;

  margin-top: ${(props) => (props.visible ? "0px" : "-75px")};
  margin-bottom: ${(props) => (props.visible ? "0px" : "25px")};
  opacity: ${(props) => (props.visible ? "1" : "0")};
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    top: 55px;
    margin-bottom: ${(props) => (props.visible ? "0px" : "35px")};
  }
`;

const ProductNavigationWrapper = styled.header`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  height: 50px;
  background: #fff;
  box-shadow: ${(props) => props.theme.boxShadow.blurred};
  padding: 0px 20px;
  user-select: none;
  z-index: 9999;
  width: 100%;
  gap: 2px;
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    height: 40px;
    top: 48px;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 0px 15px;
    justify-content: space-between !important;
    padding-right: 40px;
    top: 55px;
    & a:last-child {
      padding-right: 40px;
    }
  }
`;

const GradientSideBar = styled.div`
  display: none;
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    display: inherit;
    position: absolute;
    right: 0px;
    z-index: 9999;
    width: 40px;
    background: linear-gradient(262deg, rgba(250, 248, 246, 1) 20%, rgb(250 248 246 / 14%) 109%);
    height: 38px;
    top: 1px;
  }
`;

class ProductNavigationRow extends React.Component {
  render() {
    return (
      <ProductNavigationOuterWrapper visible={this.props.showProductRow}>
        <ProductNavigationWrapper>
          <ProductNavLink href="/products/shop/all">Shop All</ProductNavLink>
          <ProductNavLink href="/products/shop/hand-soaps">Hand Soaps</ProductNavLink>
          <ProductNavLink href="/products/shop/cleaning-sprays">Sprays</ProductNavLink>
          <ProductNavLink href="/products/shop/laundry">Laundry</ProductNavLink>
          <ProductNavLink href="/products/shop/dish">Dish Soap & Tablets</ProductNavLink>
          <ProductNavLink href="/products/shop/toilet-cleaner">Toilet Cleaner</ProductNavLink>
          {/* <ProductNavLink href="/products/shop/bar-soap">Bar Soaps</ProductNavLink> */}
        </ProductNavigationWrapper>
        <GradientSideBar />
      </ProductNavigationOuterWrapper>
    );
  }
}

export default ProductNavigationRow;
