import { withRouter } from "next/router";
import Link from "next/link";
import React, { Children } from "react";

// Used to pass through the "active" modifier fir the router asPath == the current URL
const ActiveLink = ({
  router,
  children,
  loosestAssociation,
  looseAssociation,
  ...props
}) => {
  const child = Children.only(children);

  let modifiers = "";

  // If it is a main nav link then only look at the root url
  // Don't also look for the params as you would for a sub menu active link
  // If the url even includes the the current path set to active
  if (loosestAssociation) {
    if (router.asPath.includes(props.href)) {
      modifiers = `${modifiers} active`.trim();
    }
  } else if (looseAssociation) {
    if (router.asPath === props.href) {
      modifiers = `${modifiers} active`.trim();
    }
  } else {
    if (router.asPath === props.href || router.asPath === props.href) {
      modifiers = `${modifiers} active`.trim();
    }
  }
  return <Link {...props}>{React.cloneElement(child, { modifiers })}</Link>;
};

export default withRouter(ActiveLink);
