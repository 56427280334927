import { cartState } from "@atoms/cart";
import { shopifyInventoryDictState } from "@selectors/inventory";
import { selector } from "recoil";

export const cartObjectState = selector({
  key: "cartObjectState",
  get: ({ get }) => {
    const rawCartObject = get(cartState);
    const shopifyInventoryDict = get(shopifyInventoryDictState);

    if (!rawCartObject || !shopifyInventoryDict) {
      return {};
    }
    let formattedCartObject = {
      id: rawCartObject.id,
      checkoutUrl: rawCartObject.checkoutUrl,
      estimatedCost: rawCartObject.estimatedCost,
      lines: [],
      cartQuantity: 0,
      onlyDigital: true,
      digitalSubTotal: 0,
    };
    if (rawCartObject.lines && rawCartObject.lines.edges) {
      console.clear();
      rawCartObject.lines.edges.map(function (lineItemObject) {
        if (!!shopifyInventoryDict[lineItemObject.node.merchandise.id]) {
          console.log("------");
          console.log(shopifyInventoryDict[lineItemObject.node.merchandise.id]);
          formattedCartObject.cartQuantity += lineItemObject.node.quantity;
          let formattedLineItemObject = {
            id: lineItemObject.node.id,
            quantity: lineItemObject.node.quantity,
            sellingPlan:
              lineItemObject.node.sellingPlanAllocation && lineItemObject.node.sellingPlanAllocation.sellingPlan,
            estimatedCost: lineItemObject.node.estimatedCost,
            discountAllocations: lineItemObject.node.discountAllocations,
            // attributes: lineItemObject.node.attributes,
            attributes: [],
            product: shopifyInventoryDict[lineItemObject.node.merchandise.id],
          };

          // Check to see if the cart is only comprised of digital products and that will not show the free shipping bar
          if (
            shopifyInventoryDict[lineItemObject.node.merchandise.id] &&
            shopifyInventoryDict[lineItemObject.node.merchandise.id].variation.productCategory !== "gift_card"
          ) {
            formattedCartObject.onlyDigital = false;
          }

          // Get gft card esimate to remove from free shipping
          if (
            shopifyInventoryDict[lineItemObject.node.merchandise.id] &&
            shopifyInventoryDict[lineItemObject.node.merchandise.id].variation.productCategory === "gift_card"
          ) {
            formattedCartObject.digitalSubTotal += parseFloat(lineItemObject.node.estimatedCost.totalAmount.amount);
          }

          formattedCartObject.lines.push(formattedLineItemObject);
          return lineItemObject;
        }
      });
    }
    console.log(formattedCartObject);
    return formattedCartObject;
  },
  set: ({ set }, newValue) => set(cartState, newValue),
});
