// Base Theme Styles
const theme = {
  colors: {
    white: "#fff",
    primary: "#001F60",
    secondary: "#ECF2F7",
    tertiary: "#f0f3f5",
    lavender: "#9790d6",
    lightPrimary: "#173c8a",
    extraLightPrimary: "#ECF2F7",
    darkPrimary: "#001F60",
    lightDarkPrimary: "#173c8a",
    disabled: "#bdb9b9",
    lightDisabled: "#e9e9e9",
    danger: "#FF6B4E",
    black: "#222",
    test: "#f00",
  },
  gradients: {
    lightBlue: "linear-gradient(253deg,#d8e8f6 0%,#ECF2F7 98.8%,#ECF2F7 100%)",
  },
  fonts: {
    headline: "Nazare, 'Helvetica Neue', sans-serif",
    paragraph:
      "Brandon Text, -apple-system, system-ui, system-ui, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif",
  },
  boxShadow: {
    blurred: "0px 1px 9px rgb(189 189 189 / 18%)",
  },
  breakPoints: {
    xl: "1400px",
    lg: "1200px",
    md: "996px",
    sm: "768px",
    xs: "576px",
    xxs: "456px",
    xxxs: "356px",
  },
  borderRadius: "6px",
  transitions: {
    short: "all 200ms ease",
    standard: "all 500ms ease",
    long: "all 1000ms ease",
  },
};

export default theme;
