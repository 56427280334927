import axios from "axios";

const shopify = axios.create({
  baseURL: process.env.SHOPIFY_STORE_FRONT_URL,
});
shopify.defaults.headers.common["X-Shopify-Storefront-Access-Token"] = process.env.SHOPIFY_STORE_FRONT_TOKEN;
shopify.defaults.headers.common["Content-Type"] = "application/json";

export const cartQuery = `
    id
    checkoutUrl
    createdAt
    updatedAt
    lines(first: 100, reverse: true) {
        edges {
                node {
                    id
                    merchandise {
                        ... on ProductVariant {
                            id
                        }
                    }
                    quantity
                    estimatedCost {
                        subtotalAmount {
                            amount
                            currencyCode
                        }
                        totalAmount {
                            amount
                            currencyCode
                        }
                    }
                    discountAllocations {
                        discountedAmount {
                            amount
                        }
                    }
                    attributes {
                        key
                        value
                    }
                    sellingPlanAllocation {
                        sellingPlan {
                            id
                            name
                            priceAdjustments {
                                orderCount
                                adjustmentValue {
                                    __typename 
                                }
                            }
                        }
                    }
                    
                }
            }
        }
        discountCodes {
            applicable
            code
        }
        estimatedCost {
            subtotalAmount {
                amount
                currencyCode
            }
            totalAmount {
                amount
                currencyCode
            }
            totalTaxAmount {
                amount
                currencyCode
            }
        }
    
`;

export const createCart = async () => {
  return await shopify({
    method: "post",
    data: {
      query: `
                mutation cartCreate {
                    cartCreate {
                        cart {
                            ${cartQuery}
                        }
                    }
                }
            `,
    },
  })
    .then(({ data }) => {
      return data.data.cartCreate.cart;
    })
    .catch((error) => {
      return error;
    });
};

export const fetchCart = async (cartID) => {
  return await shopify({
    method: "post",
    data: {
      query: `query {
                cart(id: "${cartID}") {
                    id
                    ... on Cart {
                        ${cartQuery}
                    }
                }
            }`,
    },
  })
    .then(({ data }) => {
      return data.data.cart;
    })
    .catch((error) => {
      return error;
    });
};

export const addLineItem = async (shopifyID, sellingPlanID, productObject, quantity) => {
  let lines;
  // If we are adding in a bundle with bundleGroup
  if (productObject.bundleGlassBottlesGroup && sellingPlanID) {
    lines = [
      {
        merchandiseId: productObject.bundleGlassBottlesGroup.shopifyID,
        sellingPlanId: null,
        quantity: quantity ? quantity : 1,
        attributes: [
          {
            key: productObject.shopifyID,
            value: sellingPlanID,
          },
        ],
      },
      {
        merchandiseId: productObject.bundleRefillsGroup.shopifyID,
        sellingPlanId: sellingPlanID,
        quantity: quantity ? quantity : 1,
        attributes: [
          {
            key: productObject.shopifyID,
            value: sellingPlanID,
          },
        ],
      },
    ];
  } else {
    lines = [
      {
        merchandiseId: shopifyID,
        sellingPlanId: sellingPlanID ? sellingPlanID : null,
        quantity: quantity ? quantity : 1,
      },
    ];
  }

  return await shopify({
    method: "post",
    data: {
      query: `mutation cartLinesAdd($lines: [CartLineInput!]!, $cartId: ID!) {
                    cartLinesAdd(lines: $lines, cartId: $cartId) {
                        cart {
                            ${cartQuery}
                        }
                }
            }`,
      variables: {
        cartId: localStorage.getItem("cartID"),
        lines: lines,
      },
    },
  })
    .then(({ data }) => {
      return data.data.cartLinesAdd.cart;
    })
    .catch((error) => {
      return error;
    });
};

export const removeLineItem = async (lineIDs) => {
  return await shopify({
    method: "post",
    data: {
      query: `mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
                cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
                    cart {
                        ${cartQuery}
                    }
                    userErrors {
                        code
                        field
                        message
                    }
                }
            }`,
      variables: {
        cartId: localStorage.getItem("cartID"),
        lineIds: lineIDs,
      },
    },
  })
    .then(({ data }) => {
      return data.data.cartLinesRemove.cart;
    })
    .catch((error) => {
      return error;
    });
};

export const updateLineItem = async (lineItemObjects) => {
  return await shopify({
    method: "post",
    data: {
      query: `mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
                    cartLinesUpdate(cartId: $cartId, lines: $lines) {
                    cart {
                        ${cartQuery}
                    }
                    userErrors {
                        code
                        field
                        message
                    }
                }
            }`,
      variables: {
        cartId: localStorage.getItem("cartID"),
        lines: lineItemObjects,
      },
    },
  })
    .then(({ data }) => {
      return data.data.cartLinesUpdate.cart;
    })
    .catch((error) => {
      return error;
    });
};

export const updateCartAttributes = async (googleClientID) => {
  return await shopify({
    method: "post",
    data: {
      query: `mutation cartAttributesUpdate($attributes: [AttributeInput!]!, $cartId: ID!) {
                    cartAttributesUpdate(attributes: $attributes, cartId: $cartId) {
                    cart {
                        id
                        attributes {
                            key
                            value
                        }
                    }
                    userErrors {
                        code
                        field
                        message
                    }
                }
            }`,
      variables: {
        cartId: localStorage.getItem("cartID"),
        attributes: [
          {
            key: "google-clientID",
            value: googleClientID,
          },
        ],
      },
    },
  })
    .then(({ data }) => {
      // // // console.log(data)
      return data.data.cartAttributesUpdate.cart;
    })
    .catch((error) => {
      return error;
    });
};
